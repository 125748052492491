import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
// import Nav from "./Nav/Nav";
import "./layout.scss";
import { Location } from "@reach/router";
import withPageView from "../lib/withPageView/withPageView";
import SEO from "../components/seo";
import FadeInContainer from "./AnimationContainers/FadeInContainer";

const seoKeyWords = [
  `gatsby`,
  `application`,
  `react`,
  `Shane Schmaltz`,
  `Shane`,
  `Schmaltz`,
  `Calgary`,
  `Software Developer`,
  `Software`,
  `Developer`,
  `Website`,
  `ShaneMakesWebsites`,
  `Web Developer`
];
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Location>
            {locationProps => (
              <div className="main-layout">
                <SEO location={locationProps} keywords={seoKeyWords} />

                {/* <Nav {...locationProps} /> */}
                <FadeInContainer>{children}</FadeInContainer>
              </div>
            )}
          </Location>
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default withPageView(Layout);
